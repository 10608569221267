<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-select v-model="search.status" clearable placeholder="请选择状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-tabs v-model="tabIdx" @tab-click="chooseTab">
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane label="已生效" name="1"></el-tab-pane>
      <el-tab-pane label="未生效" name="2"></el-tab-pane>
      <el-tab-pane label="已过期" name="3"></el-tab-pane>
    </el-tabs>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          :selectable="selectable"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="优惠券名称"
          width="250">
      </el-table-column>
      <el-table-column
          prop="send_type"
          label="发放方式"
          align="left"
          width="150">
        <template slot-scope="scope">
          {{ scope.row.send_type==1?'用户领取':'系统发放' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="有效期范围"
          align="left"
          width="280">
        <template slot-scope="scope">
          <span v-if="scope.row.time_type==2">{{ scope.row.start_time | truncate(10) }} ~ {{ scope.row.end_time | truncate(10) }}</span>
          <span
              v-if="scope.row.time_type==1">领取{{ scope.row.start_day }}天后生效 ~ 领取{{ scope.row.end_day }}天后失效</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="生效条件"
          align="left"
          width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.is_condition==0">无条件</span>
          <span v-if="scope.row.is_condition==1">满{{ scope.row.condition_val }}元</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="优惠内容"
          align="left"
          width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type==1">减{{ scope.row.coupon_val }}元</span>
          <span v-if="scope.row.coupon_type==2">打{{ scope.row.coupon_val / 10 }}折</span>
          <span v-if="scope.row.coupon_type==3">兑：{{ scope.row.goods_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="库存/总量"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.sale_sum }} / {{ scope.row.sum }}
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="生效状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.effect_status }}
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-switch
              :value="scope.row.status==1?'1':'2'"
              @change="shenheGroup(scope.row)"
              active-value="1"
              inactive-value="2"
              active-text="关闭"
              inactive-text="开启">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="130">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="tjGroup(scope.row)">数据</el-button>
          <el-button type="text" size="small" @click="sendGroup(scope.row)">发放</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="会员送券"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <member-list :type="2" :selectFunc="chooseCoupon"></member-list>
    </el-dialog>
  </div>
</template>
<script>

import MemberList from "@/components/Member/MemberList";
export default {
  components: {
    MemberList
  },
  data() {
    return {
      coupon_id: 0,
      showModal: false,
      info: false,
      tabIdx: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        coupon_status: ''
      },
      tableData: [],
      statusList: [
        {id: 1, title: '开启'},
        {id: 2, title: '关闭'},
      ]
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    chooseTab(e) {
      this.search.coupon_status = e.index
      this.handleSearch()
    },
    chooseCoupon(item) {
      var ids = []
      item.forEach(function (item) {
        ids.push(item.id)
      })
      var params = {
        ids: ids,
        coupon_id: this.coupon_id
      }
      this.$api.marking.couponSend(params, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.showModal = false
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    selectable(row,index) {
      return row.sale_sum==0
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        coupon_status: that.search.coupon_status
      }
      this.$api.marking.couponIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.$router.push({name: 'marking_coupon_edit'})
    },
    editGroup(item) {
      this.$router.push({name: 'marking_coupon_edit', query: {id: item.id}})
    },
    tjGroup(item) {
      this.$router.push({name: 'marking_coupon_log', query: {coupon_id: item.id}})
    },
    sendGroup(item) {
      this.coupon_id = item.id
      this.showModal = true
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.marking.couponDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    shenheGroup(item) {
      this.$api.marking.couponShenhe({id: item.id, status: item.status == 1 ? 2 : 1}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
